import { Box } from '@chakra-ui/layout';
import { TitleSection } from '../../TitleSection';
import { useEffect, useState } from 'react';
import {
  useFormMinimization,
  useMinimizedForm,
} from '../../../hooks/formMinimization';

export default function MinimizedForm({ type, index }) {
  const { redirect, getTitle, minimizingType } = useMinimizedForm();
  const { finishMinimization } = useFormMinimization(type);
  const [isMinimized, setIsMinimized] = useState(minimizingType !== type);
  useEffect(() => {
    if (!isMinimized) {
      setIsMinimized(true);
      finishMinimization();
    }
  }, []);
  return (
    <Box
      minWidth={isMinimized ? '1rem' : 'calc(100% - 16rem)'}
      position={isMinimized ? 'fixed' : 'absolute'}
      top={
        isMinimized ? 'calc(100vh - 11.75rem)' : { base: '5.5rem', md: '0rem' }
      }
      right={isMinimized ? 10 * index + 0.5 + 'rem' : 0}
      mt={'9rem'}
      display="inline-flex"
      transitionProperty="all"
      transitionDuration=".5s"
    >
      <TitleSection
        iconName={'arrow-up'}
        isMinimized={isMinimized}
        title={getTitle(type)}
        onClick={() => redirect(type)}
      />
    </Box>
  );
}
