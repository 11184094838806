import React, { useState } from 'react';
import { Box } from '@chakra-ui/react'
import { PRIMARY } from '../appTheme';

export const BurgerMenuIcon = ({ color, zIndex, onToggle }) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      display="inline-flex"
      width="2rem"
      height="1.5rem"
      position="relative"
      cursor="pointer"
      onClick={() => {setIsOpen(prevState => !prevState); onToggle()}}
      zIndex={zIndex ? zIndex : 4}
    >
      <Box
        display="block"
        position="absolute"
        height=".25rem"
        width={isOpen ? 0 : "100%"}
        top={isOpen ? "10px" : 0}
        left={isOpen ? "50%" : 0}
        backgroundColor={color ? color : PRIMARY}
        borderRadius=".25rem"
        style={{
          transform:"rotate(0deg)",
          transition: ".3s ease-in-out"
        }}>  
      </Box>
      <Box 
        display="block"
        position="absolute"
        height=".25rem"
        width="100%"
        borderRadius=".25rem"
        left={0}
        top="10px"
        backgroundColor={color ? color : PRIMARY}
        style={{
          transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
          transition: ".3s ease-in-out"
        }}>  
      </Box>
      <Box 
        display="block"
        position="absolute"
        height=".25rem"
        width="100%"
        backgroundColor={color ? color: PRIMARY}
        borderRadius=".25rem"
        left={0}
        top="10px"
        style={{
          transform: isOpen ? "rotate(-45deg)" : "rotate(0deg)",
          transition: ".3s ease-in-out"
        }}>  
      </Box>
      <Box 
        display="block"
        position="absolute"
        height=".25rem"
        width={isOpen ? "0%" : "100%"}
        top={isOpen ? "10px" : "20px"}
        left={isOpen ? "50%" : 0}
        backgroundColor={color ? color : PRIMARY}
        borderRadius=".25rem"
        style={{
          transform:"rotate(0deg)",
          transition: ".3s ease-in-out"
        }}>  
      </Box>
    </Box>
  );
};
