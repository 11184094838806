import { useContext, useMemo } from 'react';
import {
  FormMinimizationContext,
  TYPES,
} from '../components/app/FormMinimization/FormMinimizationContext';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

const checkMinimizeability = (element, formMinimization) => {
  if (!(element in formMinimization)) {
    throw new Error('Element "' + element + '" is not minimizable.');
  }
};

export const useFormMinimization = (element) => {
  const { formMinimization, setFormMinimization } = useContext(
    FormMinimizationContext
  );

  checkMinimizeability(element, formMinimization);

  const minimize = (id, title, referer, getValues) => {
    setFormMinimization({
      ...formMinimization,
      shouldMinimize: element,
      [element]: {
        ...formMinimization[element],
        id,
        title,
        referer,
        minimized: true,
        getData: getValues,
      },
    });
  };

  const finishMinimization = () => {
    setFormMinimization({
      ...formMinimization,
      shouldMinimize: null,
    });
  };

  const clear = () => {
    setFormMinimization({
      ...formMinimization,
      shouldMinimize: null,
      [element]: {
        ...formMinimization[element],
        minimized: false,
        data: {},
      },
    });
  };

  const getData = () => {
    return formMinimization[element].minimized
      ? formMinimization[element].getData()
      : null;
  };

  return {
    context: formMinimization[element],
    minimize,
    finishMinimization,
    clear,
    getData,
  };
};

export const useMinimizedForm = () => {
  const { formMinimization } = useContext(FormMinimizationContext);

  const router = useRouter();
  const currentPathname = router.pathname;
  const redirect = useCallback(
    async (element) => {
      checkMinimizeability(element, formMinimization);

      const urlParams = {
        pathname: formMinimization[element].referer.pathname,
      };
      if (Object.values(formMinimization[element].referer.query).length) {
        urlParams.query = {
          ...formMinimization[element].referer.query,
        };
      }
      await router.push(urlParams);
    },
    [formMinimization]
  );

  const shouldBeMinimized = useCallback(
    (element) => {
      checkMinimizeability(element, formMinimization);

      return (
        !!formMinimization[element].minimized &&
        formMinimization[element].referer.pathname !== currentPathname
      );
    },
    [formMinimization]
  );

  const getTitle = useCallback(
    (element) => {
      checkMinimizeability(element, formMinimization);

      return formMinimization[element].title;
    },
    [formMinimization]
  );

  const minimizedTypes = useMemo(() => {
    if (!formMinimization) {
      return [];
    }
    return TYPES.filter((type) => formMinimization[type].minimized);
  }, [formMinimization]);

  const minimizingType = useMemo(() => {
    return formMinimization?.shouldMinimize;
  }, [formMinimization]);

  return {
    redirect,
    shouldBeMinimized,
    getTitle,
    minimizedTypes,
    minimizingType,
  };
};
