import { Layout as PresentationalLayout } from '../Layout';
import {
  useCurrentUser,
  useOnUserDisconnection,
} from '../../hooks/authenticationStatus';
import MinimizedForm from './FormMinimization/MinimizedForm';
import { useMinimizedForm } from '../../hooks/formMinimization';
import { useMemo } from 'react';
import { Box } from '@chakra-ui/layout';
import { useRouter } from 'next/router';

export default function Layout({ children, currentForm }) {
  const user = useCurrentUser();
  const onUserDisconnection = useOnUserDisconnection();
  const router = useRouter();

  const { minimizedTypes } = useMinimizedForm();
  const minimizedFormTypes = useMemo(() => {
    return minimizedTypes.filter((type) => type !== currentForm);
  }, [minimizedTypes, currentForm]);
  const isSuperAdminLogged = useMemo(() => {
    return user.roles.includes('ROLE_ADMIN');
  }, [user]);

  return (
    <PresentationalLayout
      isSuperAdminLogged={isSuperAdminLogged}
      currentPage={router.pathname.split('/')[1]}
      loggedUser={user}
      onLogOut={async (e) => {
        e.preventDefault();
        const result = await fetch('/api/logout');
        if (result.ok) {
          onUserDisconnection();
        }
      }}
    >
      {children}

      {minimizedFormTypes.length > 0 && (
        <Box
          minWidth="1rem"
          position="fixed"
          top="calc(100vh - 12.25rem)"
          right={0}
          mt="9rem"
          overflow="show"
          style={{
            transitionProperty: 'min-width, top',
            transitionDuration: '.3s',
            transitionTimingFunction: 'ease-out',
          }}
        >
          <Box
            backgroundColor="white"
            padding=".5rem"
            height="3.25rem"
            width={10 * minimizedFormTypes.length + 0.5 + 'rem'}
            borderRadius=".5rem"
            boxShadow="lg"
            display="flex"
            flexWrap="wrap"
            gap=".5rem"
            transitionProperty="background-color, padding, border-radius, box-shadow, display, flexWrap, gap"
            transitionDuration=".3s"
          >
            {minimizedFormTypes.map((type, index) => {
              return <MinimizedForm key={type} index={index} type={type} />;
            })}
          </Box>
        </Box>
      )}
    </PresentationalLayout>
  );
}
