import React from 'react';
import { Box } from '@chakra-ui/react'
import { NavbarMenu } from './NavbarMenu';

export const LoggedLayout = ({
  children,
  loggedUser,
  isSuperAdminLogged,
  currentPage,
  onLogOut
}) => {

  return (
        <>
          <NavbarMenu
            userName={loggedUser.firstName}
            userAvatar={loggedUser.avatarUrl}
            isSuperAdminLogged={isSuperAdminLogged}
            currentPage={currentPage}
            id="navbar-menu"
            onLogOut={onLogOut}
          />
          <Box width="100%" 
            top={{base: "revert", lg: "9rem" }} 
            position={{lg: "absolute"}} 
            paddingLeft={{base: "1.5rem", lg:"16rem"}} 
            paddingRight="1.5rem"
            paddingBottom="4rem"
            zIndex={2}
            flexDirection="column"
            id="content"
          >
            {children}
          </Box>
        </>
  );
};

