import React, { useState } from 'react';
import { Box, Image, Link, Flex, Text, Divider } from '@chakra-ui/react';
import Logo from "../assets/logo-agires.svg";
import { BurgerMenuIcon } from './BurgerMenuIcon';
import { Icon } from './Icon';
import { NavLink } from './NavLink';
import {PRIMARY_GRADIENT_HORIZONTAL, PRIMARY_GRADIENT, LIGHT, SECONDARY, PRIMARY, BOX_SHADOW} from '../appTheme';
import NextLink from "next/link";

export const NavbarMenu = ({ userName, userAvatar, isSuperAdminLogged, currentPage, onLogOut }) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <>
      <Box
        display={{base:"flex", lg: "none"}}
        marginBottom={{base: "2rem", lg: "revert"}}
        justifyContent="flex-end"
        padding={{base: "1rem"}}
      >
        <BurgerMenuIcon onToggle={(() => setIsClicked(prev => !prev))}/>
      </Box>
      <Flex 
        width={{base: "calc(100vw - 4rem)", lg: "100%"}} 
        height={{base:"100vh", lg: "revert"}} 
        bg={{base: PRIMARY_GRADIENT, lg: "revert" }}
        direction={{base: "column", lg: "row"}} 
        align="start" 
        gap="2rem" 
        padding={{base: "1rem" , lg:"revert" }} 
        flexShrink={{lg: 0}} 
        flexWrap={{lg: "wrap"}}
        position={{base: isClicked ? "fixed" : "absolute", lg: "revert"}}
        top={{base: 0, lg: "revert"}}
        left={{base: isClicked ? 0 : "-100vw", lg: "revert"}}
        zIndex={{base: 4, lg: "revert"}}
        overflow={{base: "auto", lg: "revert"}}
        boxShadow={{base: "box_shadow" , lg: "none"}}
        id="layout"
        style={{
          transition: "left 0.7s ease-in-out"
        }}
      >
        <Flex
          direction={{base: "column", lg: "row"}}
          height={{lg: "12rem"}}
          bg={{base: "none", lg: PRIMARY_GRADIENT_HORIZONTAL }}
          borderRadius={{lg:"0 0 2rem 2rem" }}
          width={{lg: "100%"}}
          align={{lg: "center"}}
          paddingTop={{lg: "2.75rem"}}
          paddingBottom={{lg: "5rem"}}
          justify={{lg: "space-between"}}
          gap={{base:"2rem"}}
          id="header"
        >
          <Box
            as="strong"
            width={{base: "100%", lg: "15rem"}}
            marginLeft={{lg: '1.5rem'}}
          >
            <Link
              as={NextLink}
              href="/"
              >
            <Image
              src={Logo}
              alt="logo-agires"
              width="12.5rem"
            />
            </Link>
          </Box>
          {/* <InputGroup width={{lg:"16rem"}}>
            <InputLeftElement
            pointerEvents='none'
            children={(
              <Icon
                iconName="search"
                iconColor={theme.colors.primary}
                iconWidth="1.25rem"
              />)}
            />
            <Input
              placeholder='Nom, entreprise, contrat'
              color={theme.colors.gray}
              bg={theme.colors.light}
              fontSize=".75rem"
              borderRadius="2rem"
              onChange={e => setinputvalue(e.target.value.trim())}
            />
          </InputGroup> */}
          <Box
            display={{base: "none", lg:"flex"}}
            alignItems="center"
            gap="1rem"
          >

            <Text color={LIGHT} fontSize="sm">
              <Link
                as={NextLink}
                href="/mon-compte"
                display="flex"
                alignItems="center"
              >
                <>
                  Bonjour,
                  {' '}
                  {userName}
                  {userAvatar && (
                    <Image
                      borderRadius='full'
                      boxSize="4rem"
                      src={userAvatar}
                      alt='User Avatar'
                      ml=".5em"
                    />
                  )}
                </>
              </Link>
            </Text>
          </Box>
          <Box
            display={{base: "none", lg:"flex"}}
            flexDirection="column"
            color={LIGHT}
            gap={{lg: ".5rem"}}
            fontSize="sm"
            marginRight="4rem"
          >
            <Link display="flex" alignItems="center" onClick={onLogOut}>
              <Icon
                iconName="disconnect"
                iconColor={"light"}
                iconWidth="1.25rem"
                margin="0 .25em 0 0"
              />
              Déconnexion
            </Link>
          </Box>
        </Flex>
        <Flex
          direction="column"
          gap={{ base: "1rem", lg: 0 }}
          bg={{lg: "light"}}
          flexShrink={{lg: 1}}
          borderRadius={{lg: "1rem"}}
          width={{lg: "13rem"}}
          padding={{base: "1rem .5rem 2rem", lg: "1rem 0"}}
          marginTop={{lg: "-5rem"}}
          marginLeft={{lg: "1.5rem"}}
          boxShadow={{lg: BOX_SHADOW}}
          zIndex={3}
          color={{base: LIGHT, md: null}}
        >
          <NavLink
              label="Contacts"
              href="/contacts"
              iconName="contact"
              ishighlighted={currentPage === "contacts"}
              iconColor={{base: LIGHT, lg: SECONDARY}}
              noTextDecoration
          />
          <NavLink
              label="Entreprises"
              href="/entreprises"
              iconName="home"
              ishighlighted={currentPage === "entreprises"}
              iconColor={{base: LIGHT, lg: SECONDARY}}
              noTextDecoration
          />
          <NavLink
            label="Rendez-vous"
            href="/rendez-vous"
            iconName="calendar"
            ishighlighted={currentPage === "rendez-vous"}
            iconColor={{base: LIGHT, lg: SECONDARY}}
            noTextDecoration
          />
          <NavLink
            label="Événements"
            href="/evenements"
            iconName="events"
            ishighlighted={currentPage === "evenements"}
            iconColor={{base: LIGHT, lg: SECONDARY}}
            noTextDecoration
          />
          <NavLink
              label="Tâches"
              href="/taches"
              iconName="tasks"
              ishighlighted={currentPage === "taches"}
              iconColor={{base: LIGHT, lg: SECONDARY}}
              noTextDecoration
          />
          <NavLink
            label="Contrats"
            href="/contrats"
            iconName="contracts"
            ishighlighted={currentPage === "contrats"}
            iconColor={{base: LIGHT, lg: SECONDARY}}
            noTextDecoration
          />
          <NavLink
            label="Mails"
            href="/mails"
            iconName="message"
            ishighlighted={currentPage === "mails"}
            iconColor={{base: LIGHT, lg: SECONDARY}}
            noTextDecoration
          />
          <NavLink
            label="Documents"
            href="/documents"
            iconName="documents"
            ishighlighted={currentPage === "documents"}
            iconColor={{base: LIGHT, lg: SECONDARY}}
            noTextDecoration
          />
          {isSuperAdminLogged &&
            (
              <>
                <NavLink
                  label="Paramètres"
                  href="/parametres_ecole/editer"
                  iconName="filter"
                  ishighlighted={currentPage === "parametres_ecole"}
                  iconColor={{base: LIGHT, lg: SECONDARY}}
                  noTextDecoration
                />
                <NavLink
                  label="Utilisateurs"
                  href="/utilisateurs"
                  iconName="more-contacts"
                  ishighlighted={currentPage === "utilisateurs"}
                  iconColor={{base: LIGHT, lg: SECONDARY}}
                  noTextDecoration
                />
              </>
            )
          }
          <NavLink
              label="Tableau de bord"
              href="/tableau-de-bord"
              iconName="mail-object"
              ishighlighted={currentPage === "tableau-de-bord"}
              iconColor={{base: LIGHT, lg: SECONDARY}}
              noTextDecoration
          />
          <NavLink
            label="Mon compte"
            href="/mon-compte"
            iconName="account"
            ishighlighted={currentPage === "mon-compte"}
            iconColor={{base: LIGHT, lg: SECONDARY}}
            noTextDecoration
          />
          <Box
            display={{base: 'block', lg: 'none'}}
            color={SECONDARY}
          >
            <Divider borderColor={PRIMARY} my={8} />
            <NavLink
              label="Déconnexion"
              href="/"
              iconName="disconnect"
              onClick={onLogOut}
              iconColor={{base: SECONDARY, lg:SECONDARY}}
              noTextDecoration
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
