import React from 'react';
import {Link, Text, Box, useTheme} from '@chakra-ui/react';
import { Icon } from './Icon';
import {GRAY, LIGHTER_GRAY, SECONDARY } from '../appTheme';
import { useBreakpointValue } from '@chakra-ui/react';
import LinkComponent from './Link';

export const NavLink = ({ label, href, iconName, ishighlighted, iconColor, onClick, noTextDecoration }) => {
  const variant = useBreakpointValue(
    {
      base: iconColor && iconColor.base,
      lg: iconColor && iconColor.lg,
    },
    {
      fallback: 'md',
    }
  )
  const theme = useTheme();

  return (
    <Link
      as={LinkComponent}
      href={href}
      onClick={onClick}
      style={{ textDecoration: noTextDecoration ? "none" : null }}
    >
      <Box 
        as="span" 
        display="flex"
        gap=".75rem"
        color={{
          base: "inherit",
          lg: ishighlighted ? SECONDARY : GRAY,
        }}
        cursor="pointer"
        fontWeight="bold"
        padding={{ base: 0, lg: ".5rem 1rem" }}
        backgroundColor={{ lg: ishighlighted ? LIGHTER_GRAY : null }}
        _hover={{
          textDecoration: "none",
          color: {
            lg: SECONDARY
          },
          backgroundColor: { lg: LIGHTER_GRAY },
        }}
        >
        <Icon
          iconName={iconName}
          iconColor={theme.colors[variant]}
          iconWidth="1.5rem"
        />
        <Text as="span" textDecoration="none">
          {label}
        </Text>
      </Box>
    </Link>
  )
};