import {Box, Flex, Heading, Text, useTheme} from '@chakra-ui/react';
import { LIGHT, SECONDARY_GRADIENT } from '../appTheme';
import { Icon } from './Icon';

export const TitleSection = ({ children, iconName, title, isMinimized, onClick }) => {
    const theme = useTheme();

    return <Flex
        bg={SECONDARY_GRADIENT}
        minHeight={isMinimized ? "2.25rem" : "4rem"}
        borderRadius={isMinimized ? ".5rem" : "1.25rem"}
        height={isMinimized && "2rem"}
        alignItems="center"
        marginBottom={isMinimized ? 0 : "2rem"}
        justifyContent="space-between"
        padding={isMinimized ? ".5rem" : "1rem"}
        flexWrap="wrap"
        textAlign="center"
        gap={{base: ".75rem", lg: "0"}}
        style={{
            transitionProperty: "all",
            transitionDuration: ".3s",
        }}
        cursor={isMinimized && "pointer"}
        title={title}
        display={isMinimized && "inline-flex"}
        onClick={onClick}
    >
        <Heading
            as="h1"
            color={LIGHT}
            fontWeight="bold"
            textAlign="left"
            display="flex"
            alignItems="center"
            justifyContent={isMinimized && "center"}
            fontSize={{
                base: isMinimized ? "1rem" : "1.5rem",
                lg: isMinimized ? ".875rem" : "2rem",
            }}
            transition="all .3s"
        >
            {iconName && (
                <Box
                    as="span"
                    opacity={isMinimized ? 0 : 1}
                    transition="width .3s"
                    display={isMinimized ? 'none' : "inline-flex"}
                    alignItems="center"
                    width="2.25rem"
                    overflow="hidden"
                    height="100%"
                    mr=".5rem"
                    flexShrink={0}
                >
                    <Icon
                        iconName={iconName}
                        iconWidth={isMinimized ? "0" : "2.25rem"}
                        iconColor={theme.colors.light}
                        iconTransition="all .3s"
                    />
                </Box>
            )}
            <Text
                as="span"
                display={isMinimized && "block"}
                maxWidth={isMinimized && {
                    base: "calc(50vw - 3.5rem)",
                    md: "16ch"
                }}
                textOverflow={isMinimized && "ellipsis"}
                noOfLines={isMinimized && 1}
            >
                {title}
            </Text>
        </Heading>
        {children &&
            <Flex
                alignItems="center"
                flexWrap="wrap"
                gap={{base: ".25rem", lg: "1rem"}}
                justifyContent="space-between"
                width={{base: "100%", lg: "revert"}}
                transition="all .3s"
            >
                {children}
            </Flex>
        }
    </Flex>
};