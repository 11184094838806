import React from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react'
import {GRAY, PRIMARY, PRIMARY_GRADIENT, SECONDARY} from '../appTheme';
import LoginBackgroundImage from '../assets/login-screen--copernico--8DAN9_oi8g-unsplash.svg';
import AgiresLogoColor from '../assets/agires-logo-color.svg';
import { FormInput } from './FormInput';
import { AgiresButton } from './AgiresButton';
import {FormPasswordInput} from "./FormPasswordInput";

export const Login = ({
  isPasswordForgottenClicked,
  onSubmitLogin,
  hasLoginError,
  onClickPasswordForgotten,
  onClickBackLogin,
  onSubmitRequestPasswordReset,
  requestPasswordResetSentTo,
  hasRequestPasswordResetError,
}) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
        <Flex
          minHeight="100vh"
          background={PRIMARY_GRADIENT}
          backgroundImage={LoginBackgroundImage}
          backgroundSize="cover"
          paddingRight={{md: "50%"}}
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            perspective="1000px"
            transition=".3s ease-in-out"
            style={{
              transformStyle: "preserve-3d"
            }}
            transform={isPasswordForgottenClicked ? "rotateY(180deg)" : "rotateY(-0deg)"}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              backgroundColor="rgba(255,255,255,.95)"
              flexDirection="column"
              margin="1rem 2rem"
              minHeight={isPasswordForgottenClicked ? "20rem" : "39rem"}
              borderRadius="xl"
              boxShadow="2xl"
              maxWidth="28rem"
              flexGrow={1}
              position="relative"
              perspective="1000px"
              transition=".3s ease-in-out"
              transform={isPasswordForgottenClicked ? "rotateY(180deg)" : "rotateY(-0deg)"}
            >
              <Box
                id="loginFormUI"
                position="absolute"
                width="100%"
                padding={{base: "1rem", md: "2rem"}}
                opacity={isPasswordForgottenClicked ? 0 : 1}
                transition="opacity .3s ease-in-out"
                zIndex={isPasswordForgottenClicked ? 1 : 2}
                pointerEvents={isPasswordForgottenClicked ? "none" : null}
              >
                <Heading
                  as="h1"
                  textAlign="center"
                  color={PRIMARY}
                  marginTop="1.5em"
                  marginBottom=".75em"
                  fontSize="lg"
                >
                  Bienvenue dans votre espace
                  <Box as="span" display="block" margin="0 auto">
                    <Image
                      src={AgiresLogoColor}
                      alt="Logo AGIRES Développement"
                      width="200px"
                      height="auto"
                      m="1rem auto" />
                  </Box>
                  Relation Entreprises
                </Heading>
                <Divider borderColor={PRIMARY} my={8} />
                <Box width="100%">
                  <form onSubmit={onSubmitLogin}>
                    <FormInput 
                      id="user" 
                      label="Identifiant"
                      htmlFor="user"
                      isDirectionColumn
                      marginBottom="0"
                      isRequired
                      formgroupstatus={hasLoginError && 'invalid'}
                      errorMessage="Vérifiez votre identifiant"
                      />
                    <FormPasswordInput
                        id={"pass"}
                        label="Mot de passe"
                        htmlFor="pass"
                        isDirectionColumn
                        isRequired
                        formgroupstatus={hasLoginError && 'invalid'}
                        errorMessage="Vérifiez votre mot de passe"
                        handlePasswordDisplay={handleClick}
                        showPassword={show}
                    />
                    <Flex
                      justifyContent="space-between"
                      flexWrap={{ base: "wrap", md: null }}
                      alignItems="center"
                    >
                      <Link
                        href="#"
                        id="password-forgotten"
                        textDecoration="underline"
                        color={GRAY}
                        _hover={{ textDecoration: "none" }}
                        mb={{ base: "1rem", md: "0" }}
                        onClick={onClickPasswordForgotten}
                      >
                        Mot de passe oublié ?
                      </Link>
                      <AgiresButton type="submit" marginLeft={{ md: "1rem" }}>
                        Connexion
                      </AgiresButton>
                    </Flex>
                  </form>
                  <Text textAlign="center" color={SECONDARY} fontWeight="bold" mt="2rem">
                    Pas encore de compte ?
                  </Text>
                  <Text textAlign="center" color={PRIMARY} fontWeight="bold">
                    Contactez-nous au <Link href="tel://0432882626" textDecoration="underline">04.32.88.26.26</Link>.
                  </Text>
                </Box>
              </Box>
              <Box
                id="passwordForgottenUI"
                position="absolute"
                width="100%"
                padding={{base: "1rem", md: "2rem"}}
                opacity={isPasswordForgottenClicked ? 1 : 0}
                transition="opacity .3s ease-in-out"
                zIndex={isPasswordForgottenClicked ? 2 : 1}
              >
                {requestPasswordResetSentTo && (
                    <Text textAlign="center" mb="1rem">
                      Un mail a été envoyé à l'adresse suivante: <br/> <strong>{requestPasswordResetSentTo}</strong>
                    </Text>
                )}
                {!requestPasswordResetSentTo && (
                    <>
                <Text textAlign="center" mb="1rem">
                  Saisissez votre <strong>identifiant ou adresse email</strong>.
                </Text>
                <Text textAlign="center" mb="1rem">
                  Nous vous enverrons un lien de récupération de mot de passe par email&nbsp;:
                </Text>
                <form onSubmit={onSubmitRequestPasswordReset}>
                  <FormInput 
                    id="user_or_email"
                    label="Identifiant ou email"
                    htmlFor="user_or_email"
                    isDirectionColumn
                    isRequired
                    formgroupstatus={hasRequestPasswordResetError && 'invalid'}
                    errorMessage="Veuillez saisir une adresse d'email valide."
                  />
                  <AgiresButton
                    type="submit"
                    display="flex"
                  >
                    Envoyer le lien de récupération par email
                  </AgiresButton>
                </form>
                </>

                  )}
                <br/>

                <Center>

                <Link
                    href="#"
                    id="back-login"
                    textDecoration="underline"
                    color={GRAY}
                    _hover={{ textDecoration: "none" }}
                    onClick={onClickBackLogin}
                >
                  Retour à la page de connexion
                </Link>
              </Center>

            </Box>
            </Flex>
          </Flex>
        </Flex>
  );
};

