import React from 'react';
import { Box } from '@chakra-ui/react';
import {Login} from "./Login";
import {LoggedLayout} from "./LoggedLayout";

export const Layout = ({
  children,
  loggedUser,
  isSuperAdminLogged,
  isPasswordForgottenClicked,
  currentPage,
  onLogOut
}) => {

  return (
    <Box minHeight="100.1vh">
      {loggedUser &&
        <LoggedLayout
          loggedUser={loggedUser}
          isSuperAdminLogged={isSuperAdminLogged}
          currentPage={currentPage}
          onLogOut={onLogOut}
        >
          {children}
        </LoggedLayout>
      }
      {!loggedUser &&
        <Login isPasswordForgottenClicked={isPasswordForgottenClicked} />
      }
    </Box>
  );
};
